const isServer = typeof window === "undefined"
let supportsNativeSmoothScroll = !isServer && "scrollBehavior" in document.documentElement.style

let scrollTo = !isServer && window.scrollTo

if (!supportsNativeSmoothScroll && !isServer) {
  import("lib/scrollTo").then(({ default: fn }) => {
    console.debug("Loaded scrollTo polyfill")

    supportsNativeSmoothScroll = true
    scrollTo = opt => fn(window, opt)
  })
}

export const scrollToRef = (ref, offset = 0) => {
  if (isServer) return
  if (!ref.current) return

  if (supportsNativeSmoothScroll) {
    scrollTo({ top: ref.current.offsetTop - offset, behavior: "smooth" })
  } else {
    window.scrollTo(0, ref.current.offsetTop - offset)
  }
}

export const scrollToTop = (top = 0) => {
  if (isServer) return

  if (supportsNativeSmoothScroll) {
    scrollTo({ top, behavior: "smooth" })
  } else {
    window.scrollTo(0, top)
  }
}
